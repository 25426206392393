<template>
	<div class="about">
		<div class="versions">
			<span>{{ languageStringsAbout.serverVersion }}: {{ serverVersion.serverVersion }}</span>
			<span>{{ languageStringsAbout.operatorAppVersion }}: {{ operatorAppsVersion }}</span>
		</div>
		<div class="about-contents encore-background">
			<h1>{{ languageStringsAbout.playerPanelDocumentation }}</h1>
			<h3 class="heading">{{ languageStringsAbout.registerNewUser }}</h3>
			<ol>
				<li v-for="(item, index) in languageStringsAbout.registerNewUserOL" :key="index">{{ item }}</li>
			</ol>
			<h3 class="heading">{{ languageStringsAbout.changePassword }}</h3>
			<ol>
				<li v-for="(item, index) in languageStringsAbout.changePasswordOL" :key="index">{{ item }}</li>
			</ol>
			<h3 class="heading">{{ languageStringsAbout.forgotPassword }}</h3>
			<ol>
				<li v-for="(item, index) in languageStringsAbout.forgotPasswordOL" :key="index">{{ item }}</li>
			</ol>
			<h3 class="heading">{{ languageStringsAbout.verifyAccount }}</h3>
			<ol>
				<li v-for="(item, index) in languageStringsAbout.verifyAccountOL" :key="index">{{ item }}</li>
			</ol>
		</div>
	</div>
</template>

<script>
export default {
	name: "About",
	props: {
		serverVersion: Object,
		isMobile: Boolean,
	},
	data() {
		return {
			languageStringsAbout: require(`../dependencies/about-${this.countryCode}.json`),
		};
	},
};
</script>

<style scoped>
h3 {
	text-align: left;
}

h3.heading {
	background-color: #32373f;
	padding: 15px;
}

h3.heading {
	margin-top: 3em;
}

.about-contents {
	width: 90%;
	padding: 15px;
	margin: 15px auto 30px;
	background-color: rgb(0 0 0 / 60%);
	border-radius: 0.25em;
}

.versions {
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	margin: 5px 15px;
	text-align: center;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.about-contents {
		margin-bottom: 200px;
	}
}
</style>
